import React, { useState } from 'react'
import {
  FaArrowUp,
  FaFacebook,
  FaReddit,
  FaRocketchat,
  FaTelegram,
  FaTwitter,
  FaYoutube,
  FaMedium,
} from 'react-icons/fa'
import { ScrollToTopContainer, PlayNowButton, SocialIcon, FixIcon } from './ScrollToTopElement'
import {RightNavFourElements} from '../Navbar/Data'

const ScrollToTop = () => {

  const [isTop, setIsTop] = useState(false)

  const scrollTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

 
  window.onscroll = () => {
    if(document.body.scrollTop > 20 || document.documentElement.scrollTop > 20){
      setIsTop(true);
    }else{
      setIsTop(false);
    }
  }

  return (
    <>
      <SocialIcon isTop={isTop}>
        <FixIcon
          to={{
            pathname: RightNavFourElements.child.tele.link,
          }}
          target={'_blank'}
        >
          <FaTelegram className="footer-icon-telegram" />
        </FixIcon>
        <FixIcon
          to={{
            pathname: RightNavFourElements.child.twitter.link,
          }}
          target={'_blank'}
        >
          <FaTwitter className="footer-icon-twitter" />
        </FixIcon>
        {/* <FixIcon
          to={{
            pathname: RightNavFourElements.child.fb.link,
          }}
          target={'_blank'}
        >
          <FaFacebook className="footer-icon-facebook" />
        </FixIcon> */}
        {/* <FixIcon
          to={{
            pathname: RightNavFourElements.child.youtube.link,
          }}
          target={'_blank'}
        >
          <FaYoutube className="footer-icon-youtube" />
        </FixIcon> */}
        {/* <FixIcon
          to={{
            pathname: RightNavFourElements.child.reddit.link,
          }}
          target={'_blank'}
        >
          <FaReddit className="footer-icon-reddit" />
        </FixIcon> */}
        {/* <FixIcon
          to={{
            pathname: RightNavFourElements.child.medium.link,
          }}
          target={'_blank'}
        >
          <FaMedium />
        </FixIcon> */}
        <FixIcon
          to={{
            pathname: RightNavFourElements.child.medium.link,
          }}
          target={'_blank'}
        >
          <FaRocketchat />
        </FixIcon>
      </SocialIcon>
      <PlayNowButton isTop={isTop}>Play Now</PlayNowButton>
      <ScrollToTopContainer onClick={scrollTop} isTop={isTop}>
        <FaArrowUp />
      </ScrollToTopContainer>
    </>
  )
}

export default ScrollToTop
