import React from 'react'
import {Row} from 'react-bootstrap'

const HRDivider = () => {
  return (
    <>
      <Row className="mg-t-15 mg-bt-15">
        <div className="astrodivider">
          <div className="astrodividermask"></div>
          <span>
            <i>&#10038;</i>
          </span>
        </div>
      </Row>
    </>
  )
}

export default HRDivider
