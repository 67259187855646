import styled from "styled-components";

export const CardContainer = styled.div`
  height: auto;
  border: 2px solid #cdcdcd;
  border-radius: 15px;
  padding: 5px;
  position: relative;
  margin-bottom: 10px;
  width: 18rem;
  margin-left: 50%;
  box-shadow: 1px 3px #cdcdcd;
  justify-content: center;
  @media screen and (max-width: 768px) {
    max-width: 90%;
    margin-left: 50px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    margin-left: 10px;
    width: 15rem;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    margin-left: 60%;
    width: 15rem;
  }
`

export const CardHeading = styled.h3`
  font-size: 20px;
  font-weight: bold;
  color: #900d1f;
`

export const CardDes = styled.p `
  color: #82817d;
  font-size: 13px;
  width: 100%;
  height: 100%;
  display:flex;
  position: absolute;
  padding-left: 10px;
  padding-right: 10px;
`
export const CardBody = styled.div`
  position: relative;
  margin-top: 50px;
  max-width: 280px;
  max-height: 280px;
  transform: translateY(${({ isTrans }) => (isTrans ? isTrans + 'px' : '0px')});
  @media screen and (max-width: 768px) {
    transform: translateY(
        ${({ mobileTransY }) => (mobileTransY ? mobileTransY + 'px' : '-60px')}
      )
      translateX(
        ${({ mobileTransX }) => (mobileTransX ? mobileTransX + 'px' : '0px')}
      );
    /* transform: translateY(-65px); */
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    transform: translateX(
        ${({ touchpadLandTransX }) =>
          touchpadLandTransX ? touchpadLandTransX + 'px' : '0px'}
      )
      translateY(
        ${({ touchpadLandTransY }) =>
          touchpadLandTransY ? touchpadLandTransY + 'px' : '0px'}
      );
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    transform: translateX(
        ${({ touchpadTransX }) =>
          touchpadTransX ? touchpadTransX + 'px' : '0px'}
      )
      translateY(
        ${({ touchpadTransY }) =>
          touchpadTransY ? touchpadTransY + 'px' : '0px'}
      );
  }
`

export const CardImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  position: relative;
  bottom: 10px;
  left: 0;
  transform: scale(${({ scalevalue }) => (scalevalue ? scalevalue : 1.6)});
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    left: 0;
    transform: scale(
      ${({ scaleTouchpadLand }) =>
        scaleTouchpadLand ? scaleTouchpadLand : 1.4}
    );
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    margin-left: -4%;
    transform: scale(
        ${({ scaleTouchpad }) => (scaleTouchpad ? scaleTouchpad : 1.4)}
      )
      translateY(-30px);
  }
  @media screen and (max-width: 768px) {
    margin-left: 10px;
    transform: scale(
      ${({ scaleMobile }) => (scaleMobile ? scaleMobile : 1.21)}
    );
  }
`