import styled from "styled-components";
import {Link as LinkR} from 'react-router-dom'

const backgroundColor = '#0088cc'

export const HeroContainer = styled.div`
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 780px;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 768px) {
    height: 835px;
  }
  @media only screen and (min-width: 768.1px) and (max-width: 1024px) {
    height: 835px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    height: 835px;
    width: 100%;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    height: 750px;
    width: 100%;
  }
`

export const HeroH1 = styled.h1 `
  color: #000;
  font-size: 60px;
  text-align: center;
  padding-top: 20px;
  font-weight: 530;

  @media screen and (max-width: 768px){
    font-size: 40px;
  }

  @media screen and (max-width: 480px){
    font-size: 32px;
  }
`

export const HeroP = styled.p `
  margin-top: 24px;
  color: #82817d;
  font-size: 24px;
  text-align: center;
  max-width: 100%;
  word-wrap: break-word;
  @media screen and (max-width: 768px){
    font-size: 24px;
  }

  @media screen and (max-width: 480px){
    font-size: 18px;
  }
  @media screen and (min-width: 769px){
    margin-bottom: 30px;
    margin-top: 2px;
  }
`
export const HeroImage = styled.div`
  height: 300px;
  margin-left: 35%;

  @media screen and (min-width: 1025px){
    margin-left: 46%;
  }

  @media screen and (min-width: 768.1px) and (max-width: 1024px){
    margin-left: 40%;
  }

  @media screen and (max-width: 768px){
    margin-bottom: 50px;
    height: 240px;
    margin-left: 0;
  }

  @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px) 
    and (orientation : landscape) {
      margin-left: 24%;
  }

  @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px) 
    and (orientation : portrait) {
      margin-left: -25%;
      height: 340px;
  }
`
export const VideoButtonContainer = styled.div`
  width: 80px;
  height: 80px;
  border: 3px solid ${backgroundColor};
  background-color: rgba(144, 13, 31, 0.6);
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
  text-align: center;
  padding: 13px 26px;
  transition: transform 0.4s ease;
  z-index: 4;
  margin-top: -10px;
  &:hover {
    cursor: pointer;
    transform: scale(1.5);
  }
  @media screen and (max-width: 415px) {
    transform: translate(8px, -25px) scale(0.7);
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    transform: scale(0.7) translate(15px, -30px);
  }
`

export const HeroInforBanner = styled.div`
  width: 78%;
  border: 1px solid #cdcdcd;
  box-shadow: 1px 3px #cdcdcd;
  top: 0;
  transform: translateY(-3px);
  padding: 15px;
  border-radius: 5px;
  z-index: 0;
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    width: 80%;
    transform: translateY(-180px);
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    width: 80%;
    transform: translateY(-100px);
  }
`

export const HeroInforLinkContainer = styled.div`
  width: 100%;
  height: auto;
  background: ${backgroundColor};
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  vertical-align: middle;
  font-weight: 500;
  transition: transform 0.2s ease;
  margin-top: 8px;
  &:hover {
    cursor: pointer;
  }
  margin-left: 20px;
  @media screen and (max-width: 768px) {
    margin-left: 10%;
    margin-bottom: 10px;
    width: 78%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    margin-left: 10%;
    width: 78%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    width: 80%;
  }
`
export const HeroInforLink = styled(LinkR)`
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 15px;
  text-decoration: none;
  margin-top: -15px;
  &:hover{
    cursor: pointer;
    text-decoration: none;
    color: #fff;
  }
`
export const HeroInforListElement = styled.p`
  padding-left: 5px;
  font-size: 15px;
  font-weight: 700;
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-size: 18px;
    text-align: left;
    justify-content: left;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-size: 18px;
    text-align: left;
    justify-content: left;
  }
`
export const ImgHeroContent = styled.img `
  width: 20px;
  height: 20px;
  padding-right: 5px;
`
export const HeroImageBackground = styled.div`
  width: 100%;
  height: 400px;
  background: ${({ bg }) => (bg ? 'url(' + bg + ')' : '#fff')};
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  margin-top: -100px;
  border-radius: 30px;

  @media screen and (max-width: 415px) {
    background-size: cover;
    height: 200px;
    border-radius: 10px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    background-size: contain;
    width: 100%;
    height: 400px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    background-size: contain;
    height: 400px;
    width: 100%;
  }
`