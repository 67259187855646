import React, {useState} from 'react'
import {HeroContainer, HeroH1, HeroP, VideoButtonContainer, HeroInforBanner, HeroInforLinkContainer, HeroInforLink, HeroImageBackground } from './HeroElements'
import { Container, Row, Col, Modal} from 'react-bootstrap';
import {FaPlay} from 'react-icons/fa'

import {
  heroOne,
  heroTwo,
  heroThree,
  heroFour,
  heroFive,
  heroSix,
  mainCover,
} from './DataHero'
import HeroListElement from './HeroListElement'
import HeroBgAnimation from '../AnimationHero';

const HeroSection = () => {
  const [showVideo, setShowVideo] = useState(false);
  const openVideoModal = () => {
    setShowVideo(!showVideo)
  }
  return (
    <HeroContainer className="mg-bt-100">
      <HeroBgAnimation />
      <Container>
        <Row className="justify-content-md-center main-heading mg-b-20">
          <HeroH1>Kitten City</HeroH1>
          <HeroP>
            The 1st Metaverse NFT Game of your own adorable pets on 
            <span className="text-ton-color"> Telegram (TON)</span>
          </HeroP>
        </Row>
        <Row className="justify-content-center">
          <VideoButtonContainer onClick={openVideoModal}>
            <FaPlay />
          </VideoButtonContainer>
        </Row>
        <Row className="justify-content-center w-100">
          <HeroImageBackground bg={mainCover.img}></HeroImageBackground>
        </Row>
        <Row className="justify-content-md-center">
          <HeroInforBanner>
            <Row>
              <Col sm="12" xs="12" lg="3" md="12" className="mg-b-40">
                <HeroInforLinkContainer>
                  <HeroInforLink
                    to={{
                      pathname: '/',
                    }}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                  >
                    Raise your own Pets
                  </HeroInforLink>
                </HeroInforLinkContainer>
                <HeroInforLinkContainer>
                  <HeroInforLink
                    to={{
                      pathname: '',
                    }}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                  >
                    Documentation
                  </HeroInforLink>
                </HeroInforLinkContainer>
              </Col>
              <Col sm="12" xs="12" lg="4" md="12" className="mg-l-16">
                <HeroListElement img={heroOne.img} text={heroOne.text} />
                <HeroListElement {...heroTwo} />
                <HeroListElement {...heroThree} />
              </Col>
              <Col sm="12" xs="12" lg="5" md="12" className="mg-l-16">
                <HeroListElement {...heroFour} />
                <HeroListElement {...heroFive} />
                <HeroListElement {...heroSix} />
              </Col>
            </Row>
          </HeroInforBanner>
        </Row>
      </Container>
      <Modal
        show={showVideo}
        onHide={() => setShowVideo(false)}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Body>
          <div className="video-responsive">
            <iframe
              width=""
              height=""
              src="https://www.youtube.com/embed/K94F2RSLHP4"
              title="KittenCity Coming Soon"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              className="responsive-iframe"
            />
          </div>
        </Modal.Body> */}
      </Modal>
    </HeroContainer>
  )
}

export default HeroSection
