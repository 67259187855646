import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { PetHouseDes, PetsHouseHeader, PetsHouseImage } from './PetsHouseElement'
import {petHouseInfor, battleToEarn} from './Data'

const PetsHouse = () => {
  return (
    <>
      {/* <Container className="mg-bt-100">
        <Row className="justify-content-md-center">
          <PetsHouseHeader className="mg-bt-40">
            {petHouseInfor.heading}
          </PetsHouseHeader>
          <PetHouseDes className="mg-bt-40">{petHouseInfor.des}</PetHouseDes>
          <PetsHouseImage src={petHouseInfor.image} />
        </Row>
      </Container> */}
      <Container className="mg-b-40">
        <Row className="justify-content-md-center">
          <PetsHouseHeader className="mg-b-30">
            {battleToEarn.heading}
          </PetsHouseHeader>
          <PetHouseDes>{battleToEarn.des}</PetHouseDes>
          <PetsHouseImage src={battleToEarn.image} />
        </Row>
      </Container>
    </>
  )
}

export default PetsHouse
