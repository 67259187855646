import React from 'react'
import { Row } from 'react-bootstrap'
import { TokenContainer, TokenHeader } from './TokenomicElements'
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import ReactFC from 'react-fusioncharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

const chartData = [
  {
    label: 'IDO 30M',
    value: '30',
    color: '#00CCFD',
  },
  {
    label: 'Seed round 5M (TGE 25% monthly)',
    value: '5',
    color: '#F4C300',
  },
  {
    label: 'Team 4M (locked 1 year)',
    value: '4',
    color: '#AA00FF',
  },
  {
    label: 'Airdrop 1M',
    value: '1',
    color: '#e83333',
  },
  {
    label: 'Marketing 10M',
    value: '10',
    color: '#45D6D3',
  },
  {
    label: 'CEX/DEX Liquidity 50M',
    value: '50',
    color: '#FF44CC',
  },
]

const myDataSource = {
  chart: {
    theme: 'fusion',
    showBorder: '0',
    showToolTip: '0',
    showNames: '1',
    showValues: '1',
    showpercentvalues: '1',
    showLabels: '1',
    showLegend: '0',
    rotateLabels: '1',
    smartLineColor: '#ff33bb',
    smartLineThickness: '0.5',
    smartLineAlpha: '80',
    isSmartLineSlanted: '0',
    labelDistance: '5',
    skipOverlapLabels: '1',
    showShadow: '1',
    startingAngle: '380',
    use3DLighting: '0',
    enableSmartLabels: '1',
  },
  data: chartData,
}

const chartConfigs = {
  type: 'doughnut3D',
  dataFormat: 'json',
  dataSource: myDataSource
};

const myDataSourceMobile = {
  chart: {
    theme: 'fusion',
    showBorder: '0',
    showToolTip: '1',
    showNames: '0',
    showValues: '1',
    showpercentvalues: '1',
    showLabels: '0',
    rotateLabels: '0',
    skipOverlapLabels: '1',
    startingAngle: '380',
    use3DLighting: '0',
  },
  data: chartData,
}
const chartConfigMobile = {
  type: 'doughnut3D',
  dataFormat: 'json',
  width: '400',
  height: '400',
  dataSource: myDataSourceMobile,
}

const myDataSourceTouchDevice = {
  chart: {
    theme: 'fusion',
    showBorder: '0',
    showToolTip: '1',
    showNames: '0',
    showValues: '0',
    showLabels: '0',
    showpercentvalues: '1',
    rotateLabels: '0',
    showLegend: '1',
    skipOverlapLabels: '1',
    startingAngle: '380',
    use3DLighting: '0',
  },
  data: chartData,
}

const chartConfigTouchDevice = {
  type: 'doughnut3D',
  dataFormat: 'json',
  width: '600',
  height: '600',
  dataSource: myDataSourceTouchDevice,
}

const Tokenomic = () => {
  return (
    <>
      <TokenContainer className="mg-t-50">
        <Row className="justify-content-center">
          <TokenHeader>KittenCity Tokenomic</TokenHeader>
        </Row>
        <Row className="justify-content-center">
          <ReactFC
            width="1000"
            height="100%"
            className="tokenomic-chart"
            {...chartConfigs}
          />
          <ReactFC
            width="100%"
            height="100%"
            className="tokenomic-chart-mobile"
            {...chartConfigMobile}
          />
          <ReactFC
            width="1000"
            height="100%"
            className="tokenomic-chart-touch-device"
            {...chartConfigTouchDevice}
          />
        </Row>
      </TokenContainer>
    </>
  )
}

export default Tokenomic
