import styled from "styled-components";


export const CollectionContainer = styled.div`
  width: auto;
  height: auto;
  background: ${({bg}) => (bg ? bg : "#fff" )};
  justify-content:center;
  text-align: center;
`

export const CollectionMainContent = styled.div`
  justify-content: center;
  width: 100%;
  height: auto;
  margin: 0 auto;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
`
export const CollectionHeading = styled.h1`
  /* font-size: 18px; */
  color: #000;
  font-weight: 600;
`

export const CollectionDes = styled.p`
  font-size: 24px;
  color: #000;
  text-align: center;
`

export const CollectionListCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-left: 5px;

  @media screen and (min-width: 415px){
    flex-direction: row;
  }

  & > *{
    flex: 1;
  }
`


