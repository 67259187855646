export const nftInfor = {
  heading: "Upgradable Pets using NFT Cards",
  des: "Reward for each pet can be upgrade out of level and experience by using the custom NFT cards.",
  des1: 'NFT Cards can purchased using on NFT sale events',
  background: "#fff"
}


export const nftCard1 = {
  image: require('../../images/nft-images/card-nft-1.webp'),
}
export const nftCard2 = {
  image: require('../../images/nft-images/card-nft-2.webp'),
}
export const nftCard3 = {
  image: require('../../images/nft-images/card-nft-3.webp'),
}
export const nftCard4 = {
  image: require('../../images/nft-images/card-nft-4.webp'),
}
export const nftCard5 = {
  image: require('../../images/nft-images/card-nft-5.webp'),
}
export const nftCard6 = {
  image: require('../../images/nft-images/card-nft-6.webp'),
}
