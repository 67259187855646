import React from 'react'
import { HeroInforListElement, ImgHeroContent } from './HeroElements'


const HeroListElement = ({text, img}) => {
  return (
    <>
      <HeroInforListElement>
        <ImgHeroContent src={img} />
        {text}
      </HeroInforListElement>
    </>
  )
}

export default HeroListElement
