import styled from "styled-components";

export const CardContainer = styled.div `
  height: auto;
  padding: 10px;
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  @media screen and (max-width: 768px){
    max-width: 88%;
    margin-left: 20px;
  }
`
export const CardPetHeader = styled.div `
  position: relative;
`

export const CardHeading = styled.p `
  font-size: 20px;
  color: #000;
`
export const CardDes = styled.p `
  color: #82817d;
  font-size: 13px;
  width: 100%;
  height: 100%;
  display:flex;
  position: absolute;
  padding-left: 10px;
  padding-right: 10px;
`
export const CardNumberPets = styled.p `
  margin-left: 0;
  margin-right: auto;
`
export const CardHeart = styled.p `
  margin-left: auto;
  margin-right: 0;
  @media screen and (max-width: 768px){
    margin-right: 10px;
  }
`
export const CardBody = styled.div `
  position: relative;
  margin-top: 50px;
  max-width: 280px;
  max-height: 280px;
`

export const CardImage = styled.img `
  max-width: 100%;
  max-height: 100%;
  position: relative;
  bottom:0;
  left: 0;
  @media screen and (max-width: 768px){
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const CardFooter = styled.div `
  position: relative;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 50px;
`

export const CardFooterText = styled.div `
  overflow: hidden;
  white-space: nowrap;
  line-height: 1;
  text-overflow: ellipsis;
  text-align: left;
  padding-left: 10px;
`

export const CardAvatar = styled.img `
  border-radius: 50%;
  width: 50px;
  height: 50px;
  float: left;
`
export const CardTextFirst = styled.p `
  color: #82817d;
`

export const CardTextSecond = styled.span `
  color: #82817d;
  background-clip: text;
`