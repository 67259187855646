export const team1 = {
  name: 'Matthew Key',
  position: 'Co-Founder & Project Lead',
  avatar: require('../../images/teams/mathew.webp'),
  linkedIn: 'https://google.com',
  isCheck: false,
}
export const team2 = {
  name: 'Ichika Shibato',
  position: 'UX/UI Design Lead',
  avatar: require('../../images/teams/ichika.webp'),
  linkedIn: 'https://google.com',
  isCheck: true,
}
export const team3 = {
  name: 'Henderson Jordan',
  position: 'Blockchain Lead & CTO',
  avatar: require('../../images/teams/henderson.png'),
  linkedIn: 'https://google.com',
  isCheck: true,
}
export const team4 = {
  name: 'Emily Kane',
  position: 'Marketing & Community Lead',
  avatar: require('../../images/teams/emily.webp'),
  linkedIn: 'https://google.com',
  isCheck: false,
}
export const team5 = {
  name: 'Amber Jonathan',
  position: 'Game Developer Lead',
  avatar: require('../../images/teams/amber.webp'),
  linkedIn: 'https://google.com',
  isCheck: true,
}