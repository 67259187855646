import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { BattleContainer, BattleDes, BattleHeading, BattleImage } from './BattleElements'
import {pvpContent, referYourFriend, socialRewardsEarning} from './Data'

const BattleSection = () => {
  return (
    <>
      <BattleContainer>
        {/* <Container className="mg-bt-100">
          <Row className="justify-content-md-center center-text">
            <BattleHeading className="mg-bt-30">
              {pvpContent.heading}
            </BattleHeading>
            <BattleDes className="center-text mg-bt-40">
              {pvpContent.des}
            </BattleDes>
            <BattleImage src={pvpContent.image}></BattleImage>
          </Row>
        </Container> */}
        <Container className="mg-bt-100">
          <Row className="justify-content-md-center center-text">
            <BattleHeading className="mg-bt-30">
              {referYourFriend.heading}
            </BattleHeading>
            <BattleDes className="center-text mg-bt-40">
              {referYourFriend.des}
            </BattleDes>
            <BattleImage src={referYourFriend.image}></BattleImage>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-md-center">
            <BattleHeading className="mg-bt-30">
              {socialRewardsEarning.heading}
            </BattleHeading>
            <BattleDes className="center-text mg-bt-40">
              {pvpContent.des}
            </BattleDes>
            <div className="col-12">
              <BattleImage src={socialRewardsEarning.image}></BattleImage>
            </div>
          </Row>
        </Container>
      </BattleContainer>
    </>
  )
}

export default BattleSection
