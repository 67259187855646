export const pvpContent = {
  heading: 'Players versus Players (PVP)',
  des: 'Equiped your Pets to fight with other Players around the world',
  image: require('../../images/bg/webp/bg-2-game-pvp.webp'),
}

export const referYourFriend = {
  heading: 'Refer Your Friends',
  des: 'KittenCity is the first referable Pets game. The more you shared, the more you get',
  image: require('../../images/bg/webp/bg-4-refer-your-friend.jpg'),
}

export const socialRewardsEarning = {
  heading: 'Social Rewards Earning',
  des: 'KittenCity is the first platform that offers rewards in tokens to players who share the Pets or other in game content to Social Network',
  image: require('../../images/bg/webp/bg-5-social-rewards-earning.jpg'),
}