import React from 'react'
import { HeadLineInfor, HeadLineLink, HeadLineText } from './HeadLineInfoElements'
import {Row} from 'react-bootstrap'

const HeadLineInfo = () => {
  return (
    <>
      <HeadLineInfor>
        <Row className="justify-content-md-center center-text">
          <HeadLineText>
            News headline of Kitten City
            <HeadLineLink
              to={{
                pathname: '/',
              }}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              {' '}
              Latest News
            </HeadLineLink>
            .
          </HeadLineText>
        </Row>
      </HeadLineInfor>
    </>
  )
}

export default HeadLineInfo
