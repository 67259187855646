import styled from "styled-components";

export const BattleContainer = styled.div `
  text-align: left;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: auto;
  margin: 0 auto;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
`

export const BattleHeading = styled.h1 `
  text-align: center;
`

export const BattleDes = styled.span `
  text-align: center;
  text-align: left;
  font-size: 24px;
`

export const BattleImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 5%;
`