import React from 'react'

const HeroBgAnimation = () => {
  return (
    <>
      <div className="area">
        <ul className="circles">
          <li className="green-color"></li>
          <li className="red-color"></li>
          <li className="pink-color"></li>
          <li className="purple-color"></li>
          <li></li>
          <li className="orange-color"></li>
          <li className="pink-color"></li>
          <li className="purple-color"></li>
          <li className="green-neo-color"></li>
          <li className="red-color"></li>
        </ul>
      </div>
    </>
  )
}

export default HeroBgAnimation
