import React from 'react'
import { Container, Row, Col} from 'react-bootstrap';
import { WhatIsContainer, WhatIsCover, WhatIsDes, WhatIsHeader, WhatIsImg, WhatIsMainContent } from './WhatIsElement';
import {whatIsPetWorld} from './Data'

const WhatIsKittenCity = () => {
  return (
    <>
      <WhatIsContainer bg={whatIsPetWorld.bg} className="mg-b-20">
        <WhatIsCover />
        <Container>
          <WhatIsMainContent>
            <Row className="justify-content-md-center what-is-img">
              <Col sm="12" xs="12" md="12" lg="3">
                <WhatIsImg
                  className="custom-what-is"
                  src={whatIsPetWorld.img}
                  afterImg={whatIsPetWorld.afterImg}
                />
              </Col>
              <Col sm="12" xs="12" lg="6" md="12">
                <WhatIsHeader>{whatIsPetWorld.header}</WhatIsHeader>
                <WhatIsDes>
                Kitten City - TON Virtual NFT Pets of your own!{' '}
                  <a
                    href="#"
                    target="_blank"
                    rel={'noopener noreferrer'}
                    className="game-company-link"
                  >
                    <span className="text-orange-color">Fanta</span>{' '}
                    <span className="text-blue-color">team</span>
                  </a>{' '}
                  presents you with an addicting and amusing Play to Earn game
                  on TON (TON). Each Kitten is a digital
                  version of your real pet that requires a lot of care, and it
                  is your responsibility to provide the finest care possible.
                  Feed, dress up, take care of, and fight with other pets to
                  earn point.
                </WhatIsDes>
              </Col>
            </Row>
          </WhatIsMainContent>
        </Container>
      </WhatIsContainer>
    </>
  )
}

export default WhatIsKittenCity
