import styled from "styled-components";

export const WhatIsContainer = styled.div`
  height: 100%;
  width: 100%;
  justify-content: center;
  padding: 8.5rem 0;
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(${({ bg }) => (bg ? bg : '#cdcdcd')});
  background-size: 20rem auto;
  @media screen and (max-width: 768px) {
    background-size: 10rem auto;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    margin-top: 150px;
    margin-bottom: 80px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    margin-top: 140px;
  }
`

export const WhatIsCover = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #f3f1ee;
`

export const WhatIsMainContent = styled.div`
  width: auto;
  height: auto;
  background-color: #fff;
  height: auto;
  margin: 0 auto;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 5px;
  justify-content: center;
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    justify-content: center;
    
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    justify-content: center;
  }
`

export const WhatIsImage = styled.div `
  border-radius: 50%;
`

export const WhatIsHeader = styled.h1`
  color: #000;
  font-weight: 600;
  padding-top: 45px;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    text-align: center;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    text-align: center;
    margin-left: 15px;
  }
`

export const WhatIsDes = styled.p`
  font-size: 24px;
  color: #82817d;
  text-align: left;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    text-align: center;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    text-align: left;
    margin-left: 15px;
  }
`

export const WhatIsImg = styled.img`
  width: auto;
  left: 0;
  transform: translateX(-80px);
  @media screen and (max-width: 768px) {
    margin-left: 30%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    margin-left: 40%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    margin-left: -10px;
    margin-top: 41%;
  }
`