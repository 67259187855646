import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import ChildCardElements from './ChildCardElements'
import { CollectionContainer, CollectionDes, CollectionHeading, CollectionMainContent } from './CustomCollectionElement'
import { collectionInfo, collectionGroup1, collectionGroup2 } from './Data'

const CustomCollection = () => {
  return (
    <>
      <Container>
        <Row className="justify-content-md-center">
          <CollectionContainer bg={collectionInfo.background}>
            <CollectionMainContent>
              <CollectionHeading className="mg-bt-30">
                {collectionInfo.heading}
              </CollectionHeading>
              <CollectionDes>{collectionInfo.des}</CollectionDes>
              <CollectionDes className="mg-bt-40">
                {collectionInfo.des1}
              </CollectionDes>
              <Row className="mg-b-10">
                {collectionGroup1.map((val, key) => {
                  return (
                    <>
                      <Col sm="12" xs="12" md="4" lg="3">
                        <ChildCardElements {...val} />
                      </Col>
                    </>
                  )
                })}
              </Row>
              {/* <Row>
                {collectionGroup2.map((val, key) => {
                  return (
                    <>
                      <Col sm="12" xs="12" md="4" lg="3">
                        <ChildCardElements {...val} />
                      </Col>
                    </>
                  )
                })}
              </Row> */}
            </CollectionMainContent>
          </CollectionContainer>
        </Row>
      </Container>
    </>
  )
}

export default CustomCollection
