import styled from "styled-components";
import {Link as LinkR} from 'react-router-dom'

export const HeadLineInfor = styled.div `
  width: 100%;
  max-width: 100%;
  height: 50px;
  background: #f9f8f6;
  padding-top: 15px;
  text-align: center;

  @media screen and (max-width: 768px){
    height: 40px;
  }
`;

export const HeadLineText = styled.p`
  color: #000;
  font-size: 14px;
  font-weight: 400;
`;

export const HeadLineLink = styled(LinkR)`
  color: #900d1f;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: #900d1f;
  }
`
