import styled from "styled-components";
import {Link as LinkR} from 'react-router-dom'
import { Col } from 'react-bootstrap'
import {FaRegCopyright} from 'react-icons/fa'

export const FooterContainer = styled.div`
  text-align: center;
  background-color: ${({ bg }) => (bg ? bg : '#cdcdcd')};
  width: 100%;
  height: 300px;
  padding-top: 5px;
  position: relative;
  @media screen and (max-width: 768px) {
    height: 450px;
    margin-left: 10px;
  }
`

export const CopyRight = styled.div`
  text-align: center;
  background-color: ${({ bg }) => (bg ? bg : '#fff')};
  height: 30px;
  padding-top: 10px;
  margin-bottom: 50px;
`

export const CopyRightText = styled.span`
  /* color: #900d1f; */
  font-weight: 700;
  padding-top:10px;
`

export const FooterHeading = styled.div`
  padding-top: 4px;
  display: flex;
  text-align: left;
`
export const FooterDes = styled.div`
  left: 0;
  display: flex;
  text-align: left;
  vertical-align: middle;
`

export const FooterImage = styled.img`
  width: 60px;
  height: 60px;
`

export const FooterTextHeading = styled.h3`
  margin-top: 15px;
  margin-left: 5px;
`

export const FooterText = styled.p`
  vertical-align: middle;
  margin-top: 10px;
  margin-left: 5px;
  text-align: left;
`


export const FooterNeedHelp = styled.p`
  text-transform: uppercase;

  &::after {
    border-bottom: 2px solid #900d1f;
  }
`

export const FooterMiddleImage = styled.div`
  width: 80px;
  height: 80px;
  /* background: blue; */
  border: 1px solid #cdcdcd;
  border-radius: 50%;
  transform: translate(150px, -50px);
  position: absolute;
  @media screen and (max-width: 768px){
    width: 50px;
    height: 50px;
    transform: translate(10px, -50px);
  }
`

export const FooterDeskGroupSocialIcon = styled.div`
  padding-top: 0px;
  height: auto;
  text-align: left;
 
`
export const FooterIconElement = styled(LinkR)`
  text-decoration: none;
  color: black;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 20px;
  @media screen and (min-width: 900px) {
    font-size: 30px;
    margin-top: -10px;
    &:hover {
      transform: scale(1.2);
    }
  }
  @media screen and (max-width: 768px) {
    font-size: 25px;
  }
`

export const FooterIcon = styled.i`
  color: #900d1f;
  font-size: 18px;
  text-align: left;
  margin-left: 5px;
  margin-top: -2px;
`

export const FooterTextEmail = styled(LinkR)`
  vertical-align: middle;
  margin-left: 5px;
  text-align: left;
  text-decoration: none;
  font-size: 18px;
`

export const FooterTextWebsite = styled.p`
  color: black;
  margin-left: 10px;
`

export const GroupWebsite = styled.div`
  display: flex;
  text-align: left;
  left: 0;
`

export const HrSeperate = styled.div`
  width: 20px;
  height: 3px;
  background: #900d1f;
  justify-content: center;
  margin-left: 10px;
`

export const RightCol = styled(Col)`
  text-align:left;
  padding-left: 5%;
`

export const FaRegCopyrightIcon = styled(FaRegCopyright)`
  margin-top: -10px;
`

export const WebsiteLink = styled(LinkR)`
  text-decoration: none;
  color: #900d1f;
  position: relative;
  &:hover {
    color: #900d1f;
  }
`