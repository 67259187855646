import React, {useState} from 'react'
import {
  FaBars,
  FaSearch,
  FaAward,
  FaAngleDown,
  FaAlignLeft,
  FaTelegram,
  FaYoutube,
  FaFacebook,
  FaFileAlt,
  FaReddit,
  FaTwitter,
  FaRocketchat,
  FaFile,
  FaMedium,
} from 'react-icons/fa'

import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnContainer,
  NavBtnLink,
  NavbarRight,
  NavIcon,
  NavText,
  NavDropDownContent,
  NavDropdownElements,
  NavDropItems,
  NavbarIcon,
  NavbarText,
  NavDropHead,
  NavLogoImg,
  NavTextHead,
} from './NavbarElements'

import { Logo, RightNavFourElements, NavLinkSite } from './Data'

const Navbar = ({ toggle }) => {
  console.log("logo", Logo.img);

  const [isNav1Open, setIsNav1Open] = useState(false)
  const toggleNav1 = () => {
    setIsNav1Open(!isNav1Open)
    if (isNav2Open === true) {
      setIsNav2Open(false)
    }
  }

  const [isNav2Open, setIsNav2Open] = useState(false)
  const toggleNav2 = () => {
    setIsNav2Open(!isNav2Open)
    if(isNav1Open === true){
      setIsNav1Open(false)
    }
  }

  const handleClickOutside1 = () =>{
    setIsNav1Open(false)
    document.removeEventListener("click", handleClickOutside1)
  }

  const handleClickOutside2 = () => {
    setIsNav2Open(false)
    document.removeEventListener('click', handleClickOutside2)
  }

  return (
    <>
      <Nav>
        <NavbarContainer className="custom-nav-border">
          <NavLogo to="/">
            <NavLogoImg src={Logo.img}  alt= "logo"/>
            <NavTextHead>Kitten City</NavTextHead>
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavbarRight>
            <NavMenu>
              <NavItem>
                <NavLinks to="collection">
                  <NavIcon>
                    <FaAward />
                  </NavIcon>
                  <NavText>{NavLinkSite.collection}</NavText>
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="roadmap">
                  <NavIcon>
                    <FaSearch />
                  </NavIcon>
                  <NavText>{NavLinkSite.roadmap}</NavText>
                </NavLinks>
              </NavItem>
              {/* <NavItem>
                <NavLinks to="tokenomic">
                  <NavIcon>
                    <FaSearch />
                  </NavIcon>
                  <NavText>{NavLinkSite.tokenomic}</NavText>
                </NavLinks>
              </NavItem> */}
              <NavItem
                onClick={toggleNav1}
                onMouseLeave={() => {
                  document.addEventListener('click', handleClickOutside1)
                }}
              >
                <NavDropDownContent>
                  <NavDropHead>
                    <NavIcon>
                      <FaAlignLeft />
                    </NavIcon>
                    <NavText>{NavLinkSite.docs}</NavText>
                    <NavIcon>
                      <FaAngleDown />
                    </NavIcon>
                  </NavDropHead>
                  <NavDropdownElements isToggle={isNav1Open}>
                    <NavDropItems>
                      <NavbarText
                        to={{
                          pathname: RightNavFourElements.child.whitepaper.link,
                        }}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                      >
                        <NavbarIcon>
                          <FaFileAlt className="footer-icon-whitepaper" />
                        </NavbarIcon>
                        {RightNavFourElements.child.whitepaper.label}
                      </NavbarText>
                    </NavDropItems>
                    {/* <NavDropItems>
                      <NavbarText
                        to={{
                          pathname: RightNavFourElements.child.audit.link,
                        }}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                      >
                        <NavbarIcon>
                          <FaFile className="footer-icon-whitepaper" />
                        </NavbarIcon>
                        {RightNavFourElements.child.audit.label}
                      </NavbarText>
                    </NavDropItems> */}
                  </NavDropdownElements>
                </NavDropDownContent>
              </NavItem>
              <NavItem
                onClick={toggleNav2}
                onMouseLeave={() => {
                  document.addEventListener('click', handleClickOutside2)
                }}
              >
                <NavDropDownContent>
                  <NavDropHead>
                    <NavIcon>
                      <FaAlignLeft />
                    </NavIcon>
                    <NavText>{NavLinkSite.social}</NavText>
                    <NavIcon>
                      <FaAngleDown />
                    </NavIcon>
                  </NavDropHead>
                  <NavDropdownElements isToggle={isNav2Open}>
                    <NavDropItems>
                      <NavbarText
                        to={{ pathname: RightNavFourElements.child.tele.link }}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                      >
                        <NavbarIcon>
                          <FaTelegram className="footer-icon-telegram" />
                        </NavbarIcon>
                        {RightNavFourElements.child.tele.label}
                      </NavbarText>
                    </NavDropItems>
                    <NavDropItems>
                      <NavbarText
                        to={{
                          pathname: RightNavFourElements.child.twitter.link,
                        }}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                      >
                        <NavbarIcon>
                          <FaTwitter className="footer-icon-telegram" />
                        </NavbarIcon>
                        {RightNavFourElements.child.twitter.label}
                      </NavbarText>
                    </NavDropItems>
                    {/* <NavDropItems>
                      <NavbarText
                        to={{
                          pathname: RightNavFourElements.child.fb.link,
                        }}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                      >
                        <NavbarIcon>
                          <FaFacebook className="footer-icon-telegram" />
                        </NavbarIcon>
                        {RightNavFourElements.child.fb.label}
                      </NavbarText>
                    </NavDropItems> */}
                    {/* <NavDropItems>
                      <NavbarText
                        to={{
                          pathname: RightNavFourElements.child.youtube.link,
                        }}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                      >
                        <NavbarIcon>
                          <FaYoutube className="footer-icon-youtube" />
                        </NavbarIcon>
                        {RightNavFourElements.child.youtube.label}
                      </NavbarText>
                    </NavDropItems> */}
                    <NavDropItems>
                      <NavbarText
                        to={{
                          pathname: RightNavFourElements.child.teleChat.link,
                        }}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                      >
                        <NavbarIcon>
                          <FaRocketchat className="footer-icon-telegram" />
                        </NavbarIcon>
                        {RightNavFourElements.child.teleChat.label}
                      </NavbarText>
                    </NavDropItems>
                    {/* <NavDropItems>
                      <NavbarText
                        to={{
                          pathname: RightNavFourElements.child.reddit.link,
                        }}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                      >
                        <NavbarIcon>
                          <FaReddit className="footer-icon-reddit" />
                        </NavbarIcon>
                        {RightNavFourElements.child.reddit.label}
                      </NavbarText>
                    </NavDropItems> */}
                    {/* <NavDropItems>
                      <NavbarText
                        to={{
                          pathname: RightNavFourElements.child.medium.link,
                        }}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                      >
                        <NavbarIcon>
                          <FaMedium />
                        </NavbarIcon>
                        {RightNavFourElements.child.medium.label}
                      </NavbarText>
                    </NavDropItems> */}
                  </NavDropdownElements>
                </NavDropDownContent>
              </NavItem>
            </NavMenu>
            <NavBtn>
              <NavBtnContainer>
                <NavBtnLink to="#">Play</NavBtnLink>
              </NavBtnContainer>
            </NavBtn>
          </NavbarRight>
        </NavbarContainer>
      </Nav>
    </>
  )
}

export default Navbar
