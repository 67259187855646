import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { RoadmapContainer, RoadmapHeader } from './RoadmapElement'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaHeart } from 'react-icons/fa';
import { RoadmapData } from './Data'

const Roadmap = () => {
  return (
    <>
      <RoadmapContainer>
        <Container>
          <Row className="justify-content-center">
            <RoadmapHeader>Roadmap</RoadmapHeader>
          </Row>
          <Row className="justify-content-center">
            <Col xs="12" lg="12" sm="12" md="12">
              <VerticalTimeline>
                {RoadmapData.map((item, key) => {
                  return (
                    <VerticalTimelineElement
                      key={key}
                      className="vertical-timeline-element--work"
                      contentStyle={
                        item.isActive
                          ? { background: 'rgb(33, 150, 243)', color: '#fff' }
                          : {}
                      }
                      contentArrowStyle={
                        item.isActive
                          ? {
                              borderRight: '7px solid rgb(33, 150, 243)',
                            }
                          : { borderRight: '7px solid #fff' }
                      }
                      // date={item.timeLine}
                      iconStyle={{
                        background: '#900d1f',
                        color: '#fff',
                      }}
                      icon={<FaHeart />}
                    >
                      <h3 className="vertical-timeline-element-title">
                        {item.title}
                      </h3>
                      {item.description.map((desItem, desKey) => {
                        return (
                          <>
                            <p
                              key={Math.floor(Date.now() / 1000)}
                              className={
                                item.isActive
                                  ? 'roadmap-li active'
                                  : 'roadmap-li'
                              }
                            >
                              {desItem}
                            </p>
                            <br />
                          </>
                        )
                      })}
                    </VerticalTimelineElement>
                  )
                })}
              </VerticalTimeline>
            </Col>
          </Row>
        </Container>
      </RoadmapContainer>
    </>
  )
}

export default Roadmap
