import styled from "styled-components"

export const PetsHouseHeader = styled.h1`
  color: #000;
  font-weight: 600;
  text-align: center;
  justify-content: center;
`

export const PetHouseDes = styled.p`
  font-size: 24px;;
  color: #000;
  text-align: center;
  justify-content: left;
`

export const PetsHouseImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 5%;
`