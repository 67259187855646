import test from '../../images/bg/webp/bg-3-pet-avatar.webp'

export const NavLinkSite = {
  collection: 'Collections',
  roadmap: 'Roadmap',
  tokenomic: 'Tokenomic',
  docs: 'Docs',
  social: 'Social',
}

export const RightNavFourElements = {
  title: 'Social',
  icon: '<FaAlignLeft />',
  child: {
    fb: {
      label: 'Facebook',
      icon: '<FaFacebookSquare/>',
      link: 'google.com',
    },
    tele: {
      label: 'Telegram',
      icon: '<FaTelegram />',
      link: 'google.com',
    },
    teleChat: {
      label: 'Chat',
      icon: '<FaTelegram />',
      link: 'google.com',
    },
    youtube: {
      label: 'Youtube',
      icon: '<FaYoutube />',
      link: 'google.com',
    },
    whitepaper: {
      label: 'Whitepaper',
      icon: '<FaFileAlt />',
      link: '',
    },
    audit: {
      label: 'Audit Papper',
      icon: '<FaReddit />',
      link: 'google.com',
    },
    reddit: {
      label: 'Reddit',
      icon: '<FaReddit />',
      link: 'google.com',
    },
    twitter: {
      label: 'Twitter',
      icon: '<FaReddit />',
      link: 'https://twitter.com/kittencity_',
    },
    medium: {
      label: 'Medium',
      icon: '<FaReddit />',
      link: 'google.com',
    },
  },
}
export const Logo = {
  img: test,
}