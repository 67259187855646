import styled from 'styled-components'
import { FaTimes, FaAngleDown } from 'react-icons/fa'
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'

export const SidebarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 50%;
  height: 100%;
  background: rgba(13, 13, 13, 0.96);
  display: grid;
  align-items: center;
  top: 0;
  right: 0;
  transition: 0.4s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`

export const CloseIcon = styled(FaTimes) ` 
  color: #fff;
  transform: translateY(-10px)
`

export const Icon = styled.div`
  position: absolute;
  top: 0.3rem;
  left: -1.2rem;
  background: #0088cc;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
  border: 1px solid #0088cc;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding-left: 3px;
  padding-top: 3px;
`

export const SidebarWrapper = styled.div `
  color: #fff;
  height: 100%;
  position: absolute;
  top: 30px;
`

export const SidebarMenu = styled.ul `
  display:grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;

  @media screen and (max-width: 768px){
    grid-template-rows: repeat(6, 60px)
  }

`

export const SidebarLink = styled(LinkS)`
  display: flex;
  align-items: left;
  justify-content: left;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  margin-top: ${({ topopen }) => (topopen === 'true' ? '100px' : '0px')};
  &:hover {
    color: #900d1f;
    transition: 0.2s ease-in-out;
  }
`

export const SideBtnWrap = styled.div `
  display: flex;
  justify-content: center;
  bottom: 50px;
  left: 25px;
  position: absolute;
`

export const SidebarRoute = styled(LinkR)`
  border-radius: 5px;
  background: #0088cc;
  white-space: nowrap;
  padding: 16px 64px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
`

export const SidebarTextElem = styled.span`
  color: #fff;
  padding-left: 10px;
  width: 100px;
  max-width:100px;
  text-align: left;
`

export const FaAngleDownCustom = styled(FaAngleDown)`
  margin-left: 10px;
  font-size: 24px;
  text-align: right;
` 

export const RightIcon = styled.div`
  float: right;
  margin-top: -10px;
`

export const SidebarDropdownList = styled.div`
  height: 50px;
  width: auto;
  position: absolute;
  margin-top: 50px;
  margin-left: 10px;
  opacity: ${({ isSideBarDocOpen }) => (isSideBarDocOpen ? '1' : '0')};
  transition: 0.3s ease-in-out;
`

export const SidebarSocialDropdownList = styled.div`
  height: 50px;
  width: auto;
  position: absolute;
  margin-top: 50px;
  margin-left: 10px;
  opacity: ${({ isSideBarSocialOpen }) => (isSideBarSocialOpen ? '1' : '0')};
  transition: 0.3s ease-in-out;
`

export const SidebarChildElements = styled(LinkR)`
  color: #fff;
  display: flex;
  padding-left: 5px;
  text-decoration: none;
`

export const ChildElemText = styled.p`
  color: #fff;
  font-size: 18px;
  padding-left: 10px;
`