export const RoadmapData = [
  {
    title: 'PHASE 1',
    timeLine: 'Oct, Nov - 2022',
    description: [
      'Ideation',
      'Team formation',
      'Website & Company Registration',
    ],
    isActive: true,
  },
  {
    title: 'PHASE 2',
    timeLine: 'Dec - 2022',
    description: [
      'IDO',
      'NFT cards sell',
      'Beta game release',
      'Community Building & Events',
      'DEX Listing',
    ],
    isActive: false,
  },
  {
    title: 'PHASE 3',
    timeLine: 'Jan - 2023',
    description: [
      'Official game release',
      'Partners engagement',
      'CEX Listing',
      'CMC & CG partnership',
    ],
    isActive: false,
  },
  {
    title: 'PHASE 4',
    timeLine: 'Feb, Mar - 2023',
    description: [
      'Game release - Mobile devices',
      'Seeking partners with other game studios',
      'National Community Growth',
    ],
    isActive: false,
  },
  {
    title: 'PHASE 5',
    timeLine: 'Q2 - 2023',
    description: ['KittenCity on Others and crosschain NFT'],
    isActive: false,
  },
  {
    title: 'PHASE 6',
    timeLine: 'Q3 - 2023',
    description: ['First 3D version of KittenCity'],
    isActive: false,
  },
]