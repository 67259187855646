export const petHouseInfor = {
  heading: 'Customizable Pets House',
  des: 'Construct a perfect home for your adorable pet. To make the pet house attractive, pleasant, and comfortable, customize and decorate it with a large variety of internal decorations',
  image: require('../../images/bg/webp/bg-7-ntf-maketplace.webp'),
}

export const battleToEarn = {
  heading: 'Battle to Earn Reward',
  des: 'This is the place where your pets battle with other pets. Pets from the same levels will be selected randomly in such a battle',
  image: require('../../images/bg/webp/bg-8-pet-upgrade.jpg'),
}