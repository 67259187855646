export const heroOne = {
  text: 'Build your custom NFT Pets',
  img: require('../../images/coins.svg')
}

export const heroTwo = {
  text: 'Join Arena to earn rewards',
  img: require('../../images/gift.svg')
}

export const heroThree = {
  text: 'Tradeable Pets on NFT Marketplace',
  img: require('../../images/eggplant.svg')
}

export const heroFour = {
  text: 'Virtual Pets Metaverse (PetsVerse) Gameplay',
  img: require('../../images/puzzles.svg')
}

export const heroFive = {
  text: 'Upgradable Pets using NFT Cards',
  img: require('../../images/eggCrack.svg')
}

export const heroSix = {
  text: 'Built in Referral & Social Sharing system',
  img: require('../../images/games.svg')
}

export const mainCover = {
  img: require('../../images/bg/webp/background.jpg'),
}