import React, { useState } from 'react'

import {
  CloseIcon,
  Icon,
  SidebarContainer,
  SidebarLink,
  SidebarMenu,
  SidebarRoute,
  SidebarTextElem,
  SidebarWrapper,
  SideBtnWrap,
  FaAngleDownCustom,
  RightIcon,
  SidebarDropdownList,
  SidebarSocialDropdownList,
  ChildElemText,
  SidebarChildElements,
} from './SidebarElement'

import {
  FaAward,
  FaSearch,
  FaBookOpen,
  FaAlignLeft,
  FaFacebook,
  FaTelegram,
  FaYoutube,
  FaFileAlt,
  FaReddit,
  FaTwitter,
  FaRocketchat,
  FaMedium,
} from 'react-icons/fa'

import {
  NavLinkSite,
  RightNavFourElements,
} from '../Navbar/Data'

const Sidebar = ({isOpen, toggle}) => {
  
  const [isSideBarDocOpen, setIsSideBarDocOpen] = useState(false)
  const showDocChildElem = () => {
    setIsSideBarDocOpen(!isSideBarDocOpen)
    if (isSideBarSocialOpen === true) {
      setIsSideBarSocialOpen(false)
    }

  }
  const [isSideBarSocialOpen, setIsSideBarSocialOpen] = useState(false)
  const showSocialChildElem = () => {
    setIsSideBarSocialOpen(!isSideBarSocialOpen)
    setIsSideBarDocOpen(false)
  }

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon></CloseIcon>
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="collection">
            <FaAward />
            <SidebarTextElem>{NavLinkSite.collection}</SidebarTextElem>
          </SidebarLink>
          <SidebarLink to="whatisKittenCity">
            <FaSearch />
            <SidebarTextElem>{NavLinkSite.info}</SidebarTextElem>
          </SidebarLink>
          <SidebarLink to="roadmap">
            <FaSearch />
            <SidebarTextElem>{NavLinkSite.roadmap}</SidebarTextElem>
          </SidebarLink>
          {/* <SidebarLink to="tokenomic">
            <FaSearch />
            <SidebarTextElem>{NavLinkSite.tokenomic}</SidebarTextElem>
          </SidebarLink> */}
          <SidebarLink onClick={showDocChildElem}>
            <FaBookOpen />
            <SidebarTextElem>{NavLinkSite.docs}</SidebarTextElem>
            <RightIcon>
              <FaAngleDownCustom />
            </RightIcon>
            <SidebarDropdownList isSideBarDocOpen={isSideBarDocOpen}>
              <SidebarChildElements
                to={{
                  pathname: RightNavFourElements.child.audit.link,
                }}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                <FaReddit className="footer-icon-reddit" />
                <ChildElemText>
                  {RightNavFourElements.child.audit.label}
                </ChildElemText>
              </SidebarChildElements>
              <SidebarChildElements
                to={{
                  pathname: RightNavFourElements.child.whitepaper.link,
                }}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                <FaFileAlt className="footer-icon-whitepaper" />
                <ChildElemText>
                  {RightNavFourElements.child.whitepaper.label}
                </ChildElemText>
              </SidebarChildElements>
            </SidebarDropdownList>
          </SidebarLink>
          <SidebarLink
            to="entergame"
            onClick={showSocialChildElem}
            topopen={isSideBarDocOpen.toString()}
          >
            <FaAlignLeft />
            <SidebarTextElem>{NavLinkSite.social}</SidebarTextElem>
            <RightIcon>
              <FaAngleDownCustom />
            </RightIcon>
            <SidebarSocialDropdownList
              isSideBarSocialOpen={isSideBarSocialOpen}
            >
              <SidebarChildElements
                to={{ pathname: RightNavFourElements.child.tele.link }}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                <FaTelegram className="footer-icon-telegram" />
                <ChildElemText>
                  {RightNavFourElements.child.tele.label}
                </ChildElemText>
              </SidebarChildElements>
              <SidebarChildElements
                to={{
                  pathname: RightNavFourElements.child.twitter.link,
                }}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                <FaTwitter className="footer-icon-telegram" />
                <ChildElemText>
                  {RightNavFourElements.child.twitter.label}
                </ChildElemText>
              </SidebarChildElements>
              {/* <SidebarChildElements
                to={{
                  pathname: RightNavFourElements.child.fb.link,
                }}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                <FaFacebook className="footer-icon-telegram" />
                <ChildElemText>
                  {RightNavFourElements.child.fb.label}
                </ChildElemText>
              </SidebarChildElements> */}
              {/* <SidebarChildElements
                to={{
                  pathname: RightNavFourElements.child.youtube.link,
                }}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                <FaYoutube className="footer-icon-youtube" />
                <ChildElemText>
                  {RightNavFourElements.child.youtube.label}
                </ChildElemText>
              </SidebarChildElements> */}
              <SidebarChildElements
                to={{
                  pathname: RightNavFourElements.child.teleChat.link,
                }}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                <FaRocketchat className="footer-icon-telegram" />
                <ChildElemText>
                  {RightNavFourElements.child.teleChat.label}
                </ChildElemText>
              </SidebarChildElements>
              {/* <SidebarChildElements
                to={{
                  pathname: RightNavFourElements.child.reddit.link,
                }}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                <FaReddit className="footer-icon-reddit" />
                <ChildElemText>
                  {RightNavFourElements.child.reddit.label}
                </ChildElemText>
              </SidebarChildElements> */}
              {/* <SidebarChildElements
                to={{
                  pathname: RightNavFourElements.child.medium.link,
                }}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                <FaMedium />
                <ChildElemText>
                  {RightNavFourElements.child.medium.label}
                </ChildElemText>
              </SidebarChildElements> */}
            </SidebarSocialDropdownList>
          </SidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute to="#">Play</SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
