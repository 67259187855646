import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useState} from 'react'
import HeroSection from '../components/HeroSection';
import ScrollToTop from '../components/ScrollToTop';
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import HeadLineInfo from '../components/HeadLineInfor';
import WhatIsKittenCity from '../components/WhatIsSection';
import CustomCollection from '../components/CustomCollection';
import NFTCollections from '../components/NFTCollection';
import PetsHouse from '../components/PetsHouse';
import BattleSection from '../components/BattleSection';
import Footer from '../components/Footer';
import Tokenomic from '../components/Tokenomic';
import KittenCityTeams from '../components/Teams';
import Roadmap from '../components/Roadmap';
// import Partner from '../components/Partner';
import { Col, Container, Row } from 'react-bootstrap'
// import { CustomHR } from '../components/HrElement';
import HRDivider from '../components/HrDivider'

const Home = () => {

  const [isOpenSideBar, setIsOpenSideBar] = useState(false);

  const toggleSidebar = () => {
    setIsOpenSideBar(!isOpenSideBar)
  }

  return (
    <>
      <Row className="justify-content-center">
        <Col md="12" lg="12" xs="12" sm="12" xl="12">
          <HeadLineInfo />
        </Col>
      </Row>
      <Sidebar isOpen={isOpenSideBar} toggle={toggleSidebar} />
      <Navbar toggle={toggleSidebar} />
      <ScrollToTop />
      <Row className="justify-content-center">
        <HeroSection />
      </Row>
      <Row className="justify-content-center" id="whatisCryptopet">
        <WhatIsKittenCity />
      </Row>
      <Container>
        <Row
          className="justify-content-center mg-t-50 mg-bt-m-20"
          id="collection"
        >
          <CustomCollection />
        </Row>
        <HRDivider />
        {/* <Row className="justify-content-center mg-t-50 mg-bt-m-20">
          <NFTCollections />
        </Row>
        <HRDivider /> */}
        <Row className="justify-content-center mg-bt-m-20 mg-t-50">
          <PetsHouse />
        </Row>
        <HRDivider />
        <Row className="justify-content-center mg-bt-m-20">
          <BattleSection />
        </Row>
        <HRDivider />
        <Row className="justify-content-center mg-t-50" id="roadmap">
          <Roadmap />
        </Row>
        <HRDivider />
        {/* <Row className="justify-content-center mg-bt-m-20" id="tokenomic">
          <Tokenomic />
        </Row> */}
        {/* <HRDivider />
        <Row className="justify-content-center mg-t-50 mg-bt-m-20">
          <KittenCityTeams />
        </Row> */}
        {/* <HRDivider />
        <Row className="justify-content-center mg-t-50">
          <Partner />
        </Row> */}
      </Container>
      <Row className="justify-content-center">
        <Footer />
      </Row>
    </>
  )
}

export default Home
