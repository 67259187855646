import React from 'react'
import {
  CardBody,
  CardContainer,
  CardHeading,
  CardImage,
} from './CardElement'
import { Row, Col } from 'react-bootstrap'


const ChildCardElements = ({
  header,
  image,
  scaleVal,
  isTransform,
  isTransMobileX,
  isTransMobileY,
  isScaleMobile,
  isScaleTouchpad,
  isTransTouchpadX,
  isTransTouchpadY,
  isScaleTouchpadLand,
  isTransTouchpadXLand,
  isTransTouchpadYLand,
  id
}) => {
  return (
    <>
      <CardContainer key={id} className="mg-r-5">
        <Row className="justify-content-md-center">
          <Col sm="12" xs="12" md="12" lg="12">
            <CardHeading>{header}</CardHeading>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <CardBody
            isTrans={isTransform}
            mobileTransY={isTransMobileY}
            mobileTransX={isTransMobileX}
            touchpadTransX={isTransTouchpadX}
            touchpadTransY={isTransTouchpadY}
            touchpadLandTransX={isTransTouchpadXLand}
            touchpadLandTransY={isTransTouchpadYLand}
          >
            <CardImage
              src={image}
              scalevalue={scaleVal}
              scaleMobile={isScaleMobile}
              scaleTouchpad={isScaleTouchpad}
              scaleTouchpadLand={isScaleTouchpadLand}
              className="card-custom-pet"
            />
          </CardBody>
        </Row>
      </CardContainer>
    </>
  )
}

export default ChildCardElements
