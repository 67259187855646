import styled from "styled-components"

export const TokenContainer = styled.div`
  height: 600px;
  @media screen and (max-width: 767px) {
    height: 400px;
    width: 100%;
  }
  @media screen and (min-width: 1024px) {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
`
export const TokenHeader = styled.h1`
  color: #000;
  font-weight: 600;
  text-align: center;
  justify-content: center;
`