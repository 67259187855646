import styled from "styled-components"
import {Link as LinkR} from 'react-router-dom'

export const TeamContainer = styled.div `
  height: auto;
  margin-top: 10px;
  margin-bottom: 100px;
  width: 100%;
`

export const TeamHeader = styled.h1`
  color: #000;
  font-weight: 600;
  text-align: center;
  justify-content: center;
`

export const TeamDes = styled.p`
  font-size: 14p;
  color: #000;
  text-align: center;
  justify-content: center;
`

export const TeamMemberContainer = styled.div`
  width: auto;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
`
export const TeamMemberAvatar = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: relative;
  transition: transform 0.5s ease-in-out;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -5%);
  border: 8px solid #900d1f; 
`

export const TeamMemberName = styled.h3`
  color: #000;
  text-align: center;
  padding-top: 10px;
`

export const TeamMemberInfor = styled.div`
  justify-content: center;
  width: auto;
  height: auto;
  display: -webkit-inline-box;
  text-align: center;
`

export const TeamMemberRoles = styled.p`
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  margin-left: ${({ isLongText }) => (isLongText ? '35px' : '0px')};
`

export const TeamMemberLinks = styled.div`
  display: flex-inline;
`

export const TeamMemberSocial = styled(LinkR)`
  font-size: 20px;
  color: blue;
  padding-left: 5px;
  vertical-align:middle;
  & svg{
    margin-top: 5px;
  }
`

