import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'

export const Nav = styled.nav`
  background: #fff;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  position: relative;
  top:0;
  z-index: 10;
  width: 100%;
  @media screen and (max-width: 960px){
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    justify-content: left;
    padding: 0 12px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    padding: 0 5px;
  }
`

export const NavLogo = styled(LinkR)`
  color: #000;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
  margin-left: 50px;
  @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px) 
    and (orientation : landscape) {
      left: 0;
  }

  @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px) 
    and (orientation : portrait) {
      margin-left: 10px;
      margin-right: 2px;
  }

  @media only screen and (max-width: 768px){
    margin-left: -10px;
  }
  &:hover{
    text-decoration: none;
    color: #000;
  }
`;

export const NavTextHead = styled.div`
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    display: none;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const MobileIcon = styled.div `
  display: none;

  @media screen and (max-width: 768px){
    display: block;
    position: absolute;
    top:0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #000;
    margin-top:-10px;
    z-index: 3;
  }
`

export const NavbarRight = styled.div`
  float: right;
  display: flex;
`

export const NavMenu = styled.ul `
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;
  padding: 5px;
  margin-top: 25px;
  @media screen and (max-width: 768px){
    display: none;
  }
  @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px) 
    and (orientation : landscape) {
  }

  
  @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px) 
    and (orientation : portrait) {
      margin-top: 25px;
      text-align: center;
      transform: translateX(-40px);
  }

`
export const NavItem = styled.li`
  height: 80px;
  padding-left: 5px;
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-size: 10px;
    padding-left: 0px;
    margin-left: 2px;
  }
  &:hover {
    color: #900d1f;
  }
`

export const NavText = styled.span`
  /* margin-top: -10px; */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    margin-top: -5px;
  }
`

export const NavLinks = styled(LinkS)`
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #01bf71;
  }

  &:hover {
    color: #900d1f;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    padding: 0 0.5rem;
  }
`

export const NavLinksDropdown = styled.div`
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #01bf71;
  }

  &:hover {
    color: #900d1f;
  }
`

export  const NavBtn = styled.nav `
  display: flex;
  margin-left: 50px;
  margin-right: 10px;
  align-items: center;
  @media screen and (max-width: 768px){
    display: none;
  }
  @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px) 
    and (orientation : portrait) {
     display: none;
  }

`

export const NavBtnContainer = styled.div `
  border: none;
  background-color: #cc0088;
  z-index:1;
`


export const NavBtnLink = styled(LinkR)`
  border-radius: 5px;
  background: #0088cc;
  white-space: no-wrap;
  padding: 10px 22px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: 2px solid #0088cc;
  cursor: pointer;
  transition: all 0.3 ease-in-out;
  text-decoration: none;
  border-bottom: 4px solid #0088cc;
  z-index: 2;

  &:hover {
    color: #fff;
    transform: translateY(-10px);
  }
`

export const NavIcon = styled.p `
  margin-right: 3px;
  padding-top: 2px;
  padding-left: 5px;

  &:nth-child(2),&:nth-child(4),&:nth-child(6) {
    padding-top: 3px;
  }
`

export const NavDropDownContent = styled.div`
  position: relative;
  display: inline-block;
  &:hover {
    cursor: pointer;
    color: #900d1f;
  }
  &.active {
    border-bottom: 3px solid #01bf71;
  }
`
export const NavDropHead = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: auto;
  margin-top: 17px;
`
export const NavDropdownElements = styled.div`
  display: ${({ isToggle }) => (isToggle ? 'block' : 'none')};
  position: absolute;
  border: 1px solid #cdcdcd;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background: #cdcdcd;
  width: 165px;
`
export const NavDropItems = styled.p`
  color: black;
  padding: 8px 14px;
  text-decoration: none;
  display: block;
`

export const NavbarText = styled(LinkR)`
  display: flex;
  color: black;
  word-spacing: 2px;
  text-decoration: none;
  &:hover {
    color: blue;
  }
`
export const NavbarIcon = styled.span`
  margin-top: -4px;
  margin-right: 5px;
`

export const NavLogoImg = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
`