import styled from "styled-components"

export const RoadmapContainer = styled.div `
  height: auto;
  background: #cdcdcd;
  width: 100%;
`


export const RoadmapHeader = styled.h1`
  color: #000;
  font-weight: 600;
  text-align: center;
  justify-content: center;
  padding-top: 30px;
`

export const RoadmapDes = styled.p`
  font-size: 14p;
  color: #000;
  text-align: center;
  justify-content: left;
`