import styled from "styled-components"
import {Link as LinkR} from 'react-router-dom'

export const ScrollToTopContainer = styled.div `
  bottom: ${({isTop})=> (isTop ? '110px' : '50px')};
  outline: none;
  z-index: 2;
  background: #0eb0e6;
  transition: opacity 0.5s linear;
  opacity: ${({isTop})=> (isTop ? '1' : '0')};
  position: fixed;
  right: 30px;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  padding-top: 6px;
  padding-left: 11px;
  font-size: 18px;
  width: 40px;
  height: 40px;

  /* &:hover{
    background: #fff;
    color: #0eb0e6;
    border: 1px solid #0eb0e6 !important;
  } */

  @media screen and (max-width: 768px){
    padding-top: 8px;
    padding-left: 13px;
    font-size: 14px;
    border-radius: 65%;
    width: 40px;
    height: 40px;
  }
`;

export const PlayNowButton = styled.div`
  bottom: ${({ isTop }) => (isTop ? '190px' : '120px')};
  outline: none;
  z-index: 3;
  background: rgb(144, 13, 31);
  opacity: 1;
  position: fixed;
  right: 30px;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  padding: 15px;
  font-size: 18px;
  transition: all 0.5s ease-in-out;
  display: none;

  &:hover {
    background: #fff;
    color: rgb(144, 13, 31);
    border: 1px solid rgb(144, 13, 31) !important;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    display: block;
  }
`

export const SocialIcon = styled.div`
  bottom: 200px;
  outline: none;
  z-index: 2;
  transition: opacity 0.5s linear;
  opacity: 1;
  position: fixed;
  right: 30px;
  color: blue;
  cursor: pointer;
  padding-top: 6px;
  padding-bottom: 10px;
  padding-left: 11px;
  font-size: 30px;
  width: 40px;
  height: auto;
  padding: 10px;
  /* background: rgba(144, 13, 31, 0.2); */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    bottom: ${({ isTop }) => (isTop ? '250px' : '200px')};
    transition: all 0.5s ease-in-out;
  }
`

export const FixIcon = styled(LinkR)`
  margin-bottom: 5px;
`
