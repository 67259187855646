export const collectionInfo = {
  heading: 'Your Customizable Pets Collection',
  des: 'Pet may can be dressed as you like. Decorate your closet in the style of your favorite movie or book character',
  des1: 'Choose from a variety of accessory options, including top hats, a majestic tiara, and even a cresent hat',
  background: '#fff',
}

export const collectionGroup1 = [
  {
    id: 1,
    header: 'Winter Collection',
    image: require('../../images/char/1.png'),
    scaleVal:1,
    isTransMobileY: -20,
    isScaleTouchpad: 1.3,
    isScaleTouchpadLand: 1.4,
    isTransTouchpadXLand: 5,
    isTransTouchpadYLand: -35,
  },
  {
    id: 2,
    header: 'Summer Collection',
    image: require('../../images/char/2.png'),
    scaleVal:1,
    isTransMobileY: -20,
    isScaleTouchpad: 1.3,
    isScaleTouchpadLand: 1.4,
    isTransTouchpadXLand: 5,
    isTransTouchpadYLand: -35,
  },
  {
    id: 3,
    header: 'Sea Collection',
    image: require('../../images/char/3.png'),
    scaleVal:1,
    isTransMobileY: -20,
    isScaleTouchpad: 1.3,
    isScaleTouchpadLand: 1.4,
    isTransTouchpadXLand: 5,
    isTransTouchpadYLand: -35,
  },
] 

export const collectionGroup2 = [
  {
    id: 4,
    header: 'Honey Badger',
    image: require('../../images/char/honeybagger.webp'),
    scaleVal: 1.75,
    isTransform: -40,
    isTransMobileY: -95,
    isScaleTouchpad: 1.3,
    isTransTouchpadX: 10,
    isTransTouchpadY: -30,
    isScaleTouchpadLand: 1.4,
    isTransTouchpadXLand: 5,
    isTransTouchpadYLand: -80,
  },
  // {
  //   id: 5,
  //   header: 'Lion',
  //   image: require('../../images/char/lion.webp'),
  //   scaleVal: 0,
  //   isTransMobileY: 0,
  //   isTransMobileX: -10,
  //   isScaleMobile: 1.1,
  //   isScaleTouchpad: 1.2,
  //   isScaleTouchpadLand: 1.3,
  //   isTransTouchpadXLand: -10,
  //   isTransTouchpadYLand: -35,
  // },
  {
    id: 6,
    header: 'Panda',
    image: require('../../images/char/panda.webp'),
    scaleVal: 1.75,
    isTransform: -30,
    isTransMobileY: -85,
    isTransMobileX: 10,
    isScaleTouchpad: 1.3,
    isTransTouchpadY: -20,
    isTransTouchpadX: 10,
    isScaleTouchpadLand: 1.4,
    isTransTouchpadXLand: 10,
    isTransTouchpadYLand: -65,
  },
]
